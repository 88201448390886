import React from "react"
import { Line } from "../components/elements"
import Layout from "../components/layout"
import SEO from "../components/seo"

import {
  History,
  WhyChooseUs,
  Services,
  HeaderMain,
} from "../components/structure"

const IndexPage = () => (
  <Layout>
    <SEO title="eDigitalHub" />
    <HeaderMain />
    <Line />
    <History />
    <Line />
    <WhyChooseUs />
    <Line />
    <Services />
  </Layout>
)

export default IndexPage
